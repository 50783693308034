<template>
  <div class="p-2">
	<b-button block variant="light" type="button" class="p-3" target="_blank" href="/docs/Beschreibung_Datenschutz.pdf" >Antrag für Punktesystem</b-button>
	<b-button block variant="light" type="button" class="p-3" target="_blank" href="/docs/Punkteschluessel.pdf" >Punkteschlüssel</b-button>
  </div>  
</template>

<style>
</style>

<script>
// @ is an alias to /src


export default {
  name: 'Documents',
  data() {
	return {
	}
  },
  methods: {}
}
</script>
